import model from './model';

export default model.createController(({ $w, $widget }) => {
  $widget.onPropsChanged((oldProps, newProps) => {
    if (JSON.stringify(oldProps) !== JSON.stringify(newProps)) {
      const {
        daysLabel,
        hoursLabel,
        minutesLabel,
        secondsLabel,
        showLabels,
        showSeparators,
      } = newProps;
      updateCounterView({
        daysLabel,
        hoursLabel,
        minutesLabel,
        secondsLabel,
        showLabels,
        showSeparators,
      });
    }
  });
  const updateCounterView = ({
    daysLabel,
    hoursLabel,
    minutesLabel,
    secondsLabel,
    showLabels,
    showSeparators,
  }) => {
    $w('#labelDays').text = daysLabel;
    $w('#labelHours').text = hoursLabel;
    $w('#labelMinutes').text = minutesLabel;
    $w('#labelSeconds').text = secondsLabel;
    if (showLabels === true) {
      $w('#box18').expand();
      $w('#box19').expand();
      $w('#box20').expand();
      $w('#box21').expand();
    } else {
      $w('#box18').collapse();
      $w('#box19').collapse();
      $w('#box20').collapse();
      $w('#box21').collapse();
    }
    if (showSeparators === true) {
      $w('#seperator1').expand();
      $w('#seperator2').expand();
      $w('#seperator3').expand();
    } else {
      $w('#seperator1').collapse();
      $w('#seperator2').collapse();
      $w('#seperator3').collapse();
    }
  };
  const displayRemainingTime = ({
    daysValue,
    hoursValue,
    minutesValue,
    secondsValue,
    daysData,
    hoursData,
    minutesData,
    secondsData,
  }) => {
    const daysElement = $w('#Days');
    const hoursElement = $w('#Hours');
    const minutesElement = $w('#Minutes');
    const secondsElement = $w('#Seconds');
    let isSecondsValueChanged = false;
    let isMinutesValueChanged = false;
    let isHoursValueChanged = false;
    let isDaysValueChanged = false;

    if (daysElement.text !== daysValue) {
      addAnimationToElement(daysElement).then(() => {});
      isDaysValueChanged = true;
    }
    if (hoursElement.text !== hoursValue) {
      addAnimationToElement(hoursElement).then(() => {});
      isHoursValueChanged = true;
    }

    if (minutesElement.text !== minutesValue) {
      addAnimationToElement(minutesElement).then(() => {});
      isMinutesValueChanged = true;
    }

    if (secondsElement.text !== secondsValue) {
      addAnimationToElement(secondsElement).then(() => {});
      isSecondsValueChanged = true;
    }

    daysElement.text = daysValue;
    hoursElement.text = hoursValue;
    minutesElement.text = minutesValue;
    secondsElement.text = secondsValue;

    $w('#repeater1').data = daysData;
    $w('#repeater2').data = secondsData;
    $w('#repeater3').data = minutesData;
    $w('#repeater4').data = hoursData;

    const repeaterArray = [
      { repeaterElement: $w('#repeater1'), textId: '#text1', boxId: '#box23' },
      { repeaterElement: $w('#repeater2'), textId: '#text2', boxId: '#box24' },
      { repeaterElement: $w('#repeater3'), textId: '#text3', boxId: '#box26' },
      { repeaterElement: $w('#repeater4'), textId: '#text4', boxId: '#box28' },
    ];
    repeaterArray.forEach((repeaterData) => {
      const { repeaterElement, textId, boxId } = repeaterData;
      repeaterElement.onItemReady(($item, itemData) => {
        const repeatedElement = $item(textId);
        if (repeatedElement.text !== itemData.value) {
          addAnimationToElement($item(boxId)).then(() => {});
        }
        repeatedElement.text = itemData.value;
      });
    });
  };

  const addAnimationToElement = async (element) => {
    const { animationType } = $widget.props;
    if (animationType !== '') {
      await element.hide();
      switch (animationType) {
        case 'slide_down':
          await element.show('slide', { duration: 1000, direction: 'top' });
          break;
        case 'slide_up':
          await element.show('slide', { duration: 1000, direction: 'bottom' });
          break;
        case 'roll_left':
          await element.show('roll', { duration: 1000, direction: 'left' });
          break;
        case 'roll_top':
          await element.show('roll', { duration: 1000, direction: 'top' });
          break;
        default:
          await element.show(animationType, { duration: 1000 });
          break;
      }
    }
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const {
        daysLabel,
        hoursLabel,
        minutesLabel,
        secondsLabel,
        showLabels,
        showSeparators,
      } = $widget.props;
      updateCounterView({
        daysLabel,
        hoursLabel,
        minutesLabel,
        secondsLabel,
        showLabels,
        showSeparators,
      });
    },
    exports: { displayRemainingTime },
  };
});
